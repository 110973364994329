/**
 * One env config file to rule them all
 */
export const ENV_CONFIG = {
  // * Shared values between staging and production
  TURNSTILE_SITEVERIFY_URL: 'https://challenges.cloudflare.com/turnstile/v0/siteverify',
  TURNSTILE_SITE_KEY: '0x4AAAAAAACRwYjpnMIaYGPg',
  SENTRY_DSN: 'https://b9090af4e3d444c3c4267070cbc38fec@o4508358359187456.ingest.de.sentry.io/4508806660948048',
  NODE_ENV: process.env.NODE_ENV,
  //* Client env variables
  DIRECTUS_URL: process.env.NEXT_PUBLIC_DIRECTUS_URL,
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  RELEASE_ID: process.env.NEXT_PUBLIC_RELEASE_ID,
  //* Server env variables
  TURNSTILE_SECRET_KEY: process.env.TURNSTILE_SECRET_KEY,
} as const
